import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col, Button, Image } from 'react-bootstrap'
import arrowRight from '../../assets/icons/right_arrow_icon.svg'

const KnowledgeBaseLandingContainer = (props) => {
  return (
    <>
      <Container className='landing-banner-background' fluid>
        <Container>
          <Row className='justify-content-center'>
            <Col className='text-center col-8' md='10'>
              <h1 className='h1'>{props.landingPageData.title}</h1>
              <h4>{props.landingPageData.subtitle}</h4>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container className='articles-section my-4 my-md-5'>
        <Row className='mb-5 justify-content-center'>
          <Col className='text-center col-10' md='8'>
            <h2>{props.landingPageData.articlesListHeading}</h2>
          </Col>
        </Row>
        <Row className='justify-content-center justify-content-md-between'>
          {props.data.map((article, index) => {
            return (
              <Col className='pr-md-1 mb-1 col-10' md='6' key={index}>
                <Link
                  to={article.node.slug}
                  className='card rounded-0 border-left-0 border-right-0'
                >
                  <div className='card-body pl-0 d-flex'>
                    <Img className='w-50' fixed={article.node.image.fixed} />
                    <div className='pl-3 d-flex flex-column justify-content-start w-100'>
                      <p className='h6'>{article.node.type}</p>
                      <p className='h5'>{article.node.title}</p>
                    </div>
                  </div>
                </Link>
              </Col>
            )
          })}
        </Row>
      </Container>
      <Container className='questions-section py-5' fluid>
        <Container>
          <Row className='justify-content-center text-white mb-5'>
            <Col className='text-center col-10' md='8'>
              <h2>{props.landingPageData.topQuestionsHeading}</h2>
            </Col>
          </Row>
          <Row className='justify-content-center text-white mt-3 mt-md-5 pt-md-5'>
            {JSON.parse(
              props.landingPageData.trademarkQuestions.internal.content
            ).questions.map((data, index) => {
              return (
                <Col className='question col-10 mb-5' md='6' key={index}>
                  <p className='h4 text-white'>{data.question}</p>
                  <p>{data.content}</p>
                </Col>
              )
            })}
          </Row>
          <Row className='justify-content-center my-3'>
            <Col className='col-8' md='4' lg='2'>
              <Button
                as={Link}
                to={`/${props.locale.toLowerCase()}/faqs`}
                className='py-3'
                variant='light'
                block
              >
                {props.landingPageData.readMoreButton}
              </Button>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col className='col-10 my-5' md='12'>
              <div className='card rounded-0 border-0 why-us-card'>
                <div className='card-body my-3 my-md-0 px-4 py-5 p-md-5 d-md-flex justify-content-between align-items-center'>
                  <p className='h4 w-100'>{props.WhyUsData?.title}</p>
                  <p className='px-md-5'>
                    {props.WhyUsData?.description?.description}
                  </p>
                  <a
                    className='w-75 d-flex align-items-center'
                    href={`/${props.locale.toLowerCase()}/why-us`}
                  >
                    {props.WhyUsData?.button}
                    <Image
                      className='ml-3'
                      src={arrowRight}
                      aria-hidden='true'
                      fluid
                      width='29px'
                      height='28px'
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}

export default KnowledgeBaseLandingContainer
