import React from 'react'
import logo from '../../../assets/images/PL-Icon.svg'

const Loader = ({ loading }) => {
  if (loading) {
    return (
      <div className='loader'>
        <div className='loader-half-overlay-top' />
        <div className='loader-logo-wrapper' />
        <img className='loader-logo' src={logo} alt='Heirlume logo' />
        <div className='loader-half-overlay-bottom' />
        <div className='loader-animation' />
      </div>
    )
  }
  return null
}

export default Loader
